/*

1. Posicionamiento
2. Box Model
3. Tipografía
4. Visuales
5. Otros

*/


:root{
    --dark-color: #141516;
    --light-color: #ffffff;
    --gray-color: #3b3e41;
    --header-height-mobile: 50px;
    --mobile-font: 0.8em;
    --title-font: 1.75em;
    --desktop-text-font: 1.2rem;
    --min-height-desktop: 680px;
    --title-desktop-font: 2.3rem;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


.fifth-section-main-container{
    width: 100%;
    min-width: 320px;
    min-height: 55vh;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(20,21,22,0.55) 0%, rgba(20,21,22,1) 100%), url('../../../../assets/ligas.jpg');
    background-size:cover;
    background-position: center;
    object-fit: cover;
}

.title-fifth-section-container{
    width: 90%;
    height: 6vh;
    align-self: center;
    margin-top: 4vh;
    text-align: center;
}

.title-fifth-section-container h2{
    font-size: var(--title-font);
    text-transform: uppercase;
    color: var(--light-color);
    margin: 15px 0;

}

.ligas-body-container{
    width: 90%;
    min-height: 58vh;
    align-self: center;
    padding: 25px 0;
    justify-self: center;
    align-self: center;
}

.ligas-container{
    width: 100%;
    min-height: 20vh;
    text-align: center;
}

.ligas-container h3{
    color: var(--light-color);
    font-size: var(--mobile-font);
    font-weight: 700;
    width: 100%;
    margin-top: 20px;
}

.ligas-container p{
    color: var(--light-color);
    font-size: var(--mobile-font);
    font-weight: 500;
    width: 100%;
    margin-top: 5px;

}

.ligas-container a{
    color: var(--light-color);
    font-size: var(--mobile-font);
    font-weight: 500;
    width: 100%;
    margin-top: 5px;
    text-decoration: none;
}

.menu-footer-options{
    width: 100%;
    height: 20vh;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-footer-options ul{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    color: white;
    list-style: none;
}

.menu-footer-options li{
    width: 45%;
    text-align: center;
    margin: 5px;
    font-weight: 700;
}

.menu-footer-options a{
    text-decoration: none;
    color: var(--light-color);
}

.legalidad-integral-copy{
    width: 100%;
    height: 7vh;
    display: flex;
    margin-top: 2vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.legalidad-integral-copy p{
    color: var(--light-color);
    font-size: var(--mobile-font);
    font-weight: 500;
    text-align: center;
}

@media (max-height: 430px){
   
    .ligas-body-container{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

@media(min-width: 1000px){
    .fifth-section-main-container{
        min-height: 300px;
        height: 60vh;
        display: flex;
        align-items: flex-start;
    }

    .title-fifth-section-container h2{
        font-size: var(--title-desktop-font);
        font-weight: 700;
        text-align: center;
    }

    .ligas-body-container{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        max-width: 2550px;
        
    }
    .ligas-container{
        width: 50%;
        margin-bottom: 65px;
    }

    .ligas-container h3{
        color: var(--light-color);
        font-size: var(--desktop-text-font);
        font-weight: 700;
        width: 100%;
        margin-top: 15px;
    }
    
    .ligas-container p{
        color: var(--light-color);
        font-size: var(--desktop-text-font);
        font-weight: 500;
        width: 100%;
        margin-top: 5px;
    
    }

    .ligas-container a{
        color: var(--light-color);
        font-size: var(--desktop-text-font);
        font-weight: 500;
        width: 100%;
        margin-top: 5px;
        text-decoration: none;
    }

    .menu-footer-options ul{
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
    }

    .menu-footer-options li{
        width: auto;
        margin-right: 25px;
    }
    .menu-footer-options a{
        font-size: var(--desktop-text-font);
        font-weight: 500;
    }
    .legalidad-integral-copy{
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 55%;
        height: 20vh;
        align-self: center;
        background: var(--dark-color);
    }
    .legalidad-integral-copy p{
        font-size: var(--desktop-text-font);
    }
    .legalidad-integral-copy{
        margin: 0;
    }
}

@media(min-width: 2500px){
    .fifth-section-main-container{
        height: 30vh;
    }

    .ligas-body-container{
        min-height: 320px;
    }
}

@media (min-width: 350px) and (max-width: 999px){
    .fifth-section-main-container{
        height: 90vh;
    }

    .ligas-container h3{
        color: var(--light-color);
        font-size: 1em;
        font-weight: 700;
        width: 100%;
        margin-top: 15px;
    }
    
    .ligas-container p{
        color: var(--light-color);
        font-size: 1em;
        font-weight: 500;
        width: 100%;
        margin-top: 5px;
    }

    .ligas-container a{
        color: var(--light-color);
        font-size: 1em;
        font-weight: 500;
        width: 100%;
        margin-top: 5px;
        text-decoration: none;
    }

    .legalidad-integral-copy{
        margin: 0;
    }
}

@media (min-width: 2800px){
    .fifth-section-main-container{
        min-height: 300px;
        height: 25vh;
    }

    .ligas-body-container{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        min-height: 30vh !important;
        max-width: 1440px;
    }
    .ligas-container{
        width: 50%;
        margin-bottom: 65px;
    }
}