/*

1. Posicionamiento
2. Box Model
3. Tipografía
4. Visuales
5. Otros

*/


:root{
    --dark-color: #141516;
    --light-color: #ffffff;
    --gray-color: #3b3e41;
    --header-height-mobile: 50px;
    --mobile-font: 0.8em;
    --title-font: 1.75em;
    --desktop-text-font: 1.2rem;
    --min-height-desktop: 680px;
    --title-desktop-font: 2.3rem;
    --line-height-full-size: 76px;
    --line-heght-middle-size: 36px; 
    --full-size-max-width: 2250px;
    --desktop-full-size-font: 3em;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


.first-section-subcontainer{
    min-height: 110vh;
    width: 100%;
    background: linear-gradient(180deg, rgba(20,21,22,0.55) 0%, rgba(20,21,22,1) 100%), url('../../../../assets/subpage.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: contain;
    display: flex;
    flex-direction: column;
    text-align: cetner;
}

.second-section-logo{
    height: 230px !important;
    justify-self: center;
    margin: 15px 0;
    
}

.text-container-second-section{
    width: 90%;
    height: calc(50vh - var(--header-height-mobile));
    align-self: center;
    display: flex;
    align-items: flex-end;
    padding-bottom: 15px;
    margin-bottom: 25px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-self: center;
    justify-content: center;
}

.text-container-second-section h2{
    font-size: var(--title-font);
    color: var(--light-color);
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
}

.text-container-second-section p{
    margin-top: 15px;
    font-size: var(--mobile-font);
    font-weight: 500;
    color: var(--light-color);
    text-align: justify;
    text-align-last: center;
}

@media (min-width: 1000px){
    .first-section-subcontainer{
        min-height: 400px;
        height: 97vh;
        justify-content: center;
        position: relative;
    }

    .text-container-second-section{
        width: 80%;
        min-height: 250px;
        max-width: 1440px;
        justify-content: flex-end;
        position: absolute;
        bottom: 2%;
        
    }

    .text-container-second-section h2{
        width: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-size: var(--title-desktop-font);
        margin-top: 25px;
    }

    .text-second-section{
        text-align: justify;
        text-align-last: center;
        color: var(--light-color);
        padding: 5px 15px;
        font-size: var(--desktop-text-font) !important;
        font-weight: 500;
        font-family: "Montserrat";
        line-height: var(--line-heght-middle-size);
    }

    .second-section-logo{
        position: absolute;
        top: 20%;
    }
}

@media (min-width: 350px) and (max-width: 999px){

    .text-second-section{
        font-size: 1em !important;
    }
}

@media(min-width: 2400px){
    .first-section-subcontainer{
        height: 80vh;
    }
    .text-container-second-section{
        position: absolute;
        bottom: 15%;
    }
}

@media (min-width: 2800px){
    .first-section-subcontainer{
        min-height: var(--min-height-desktop);
        height: 70vh;
        justify-content: space-around;
        padding: 90px 0;
        position: relative;
    }

    .second-section-logo{
        position: absolute;
        top: 25%;
    }

    
   
}