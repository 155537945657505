/*

1. Posicionamiento
2. Box Model
3. Tipografía
4. Visuales
5. Otros

*/


:root{
    --dark-color: #141516;
    --light-color: #ffffff;
    --gray-color: #3b3e41;
    --header-height-mobile: 50px;
    --mobile-font: 0.8em;
    --title-font: 1.75em;
    --desktop-text-font: 1.2rem;
    --min-height-desktop: 680px;
    --title-desktop-font: 2.3rem;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


.fourth-section-main-container{
    min-height: 100vh;
    min-width: 320px;
    display: flex;
    flex-direction: column;
}

.fourth-section-title-container{
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4vh;
    width: 90%;
    align-self: center;
}

.fourth-section-main-container h2{
    font-size: var(--title-font);
    color: var(--dark-color);
    font-weight: 700;
    text-transform: uppercase;
}

.fourth-section-contact-info{
    width: 90%;
    height: 40vh;
    align-self: center;
    margin-top: 4vh;
    display: flex;
    
    flex-direction: column;
    justify-content: space-around;
    
    /* box-shadow: 0 2px 2px 0 rgba(76, 76, 77, 0.37);
    
    border-radius: 10px; */
}

.light-logo-container{
    width: 100%;
    height: 30%;
    padding: 5px 15px;
}

.light-logo-container img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.location-container{
    width: 100%;
    display: flex;
    height: 30%;
    padding: 5px;
   
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.location-text{
    width: 85%;
    font-size: var(--mobile-font);
    font-weight: 500;
}

.icon-location{
    width: 10%;
    height: 100%;
    margin: 15px;
    
}


.map-info{
    margin: 0;
}

.fourth-section-wrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media (min-width: 1000px){
    .fourth-section-main-container{
        flex-direction: row;
        flex-wrap: wrap;
        min-height: 550px;
        height: 60vh;
        justify-content: center;       
    }
    .fourth-section-title-container{
        width: 100%;
        align-self: auto;
        
    }
    .fourth-section-title-container h2{
        font-size: var(--title-desktop-font);
        font-weight: 700;
        text-align: center;
    }
    .fourth-section-contact-info{
        width: 45%;
        height: 300px;
        margin: 0;
        margin-right: 5px;
        align-items: center;
        align-self: auto;
        margin-bottom: 105px;
    }

    .light-logo-container{
        width: 80%;
    }
    .location-container{
        width: 80%;
        
    }
    
    .icon-location{
        width: 25px;
        height: 25px;
        
        color: var(--gray-color);
        
    }

    .location-text{
        font-size: var(--desktop-text-font);
        font-weight: 500;
        text-align: justify;
        
    }

    .map-info{
        padding: 0 5%;
    }

    .fourth-section-wrapper{
        flex-direction: row;
        width: 90%;
        align-items: center;
        justify-content: center;
        max-width: 1440px;
        align-self: center;
        justify-self: center;
    }

}


@media (min-width: 350px) and (max-width: 999px){
    
    .location-text{
        font-size: 1em;
        font-weight: 500;
        text-align: justify;
        
    }

    .icon-location{
        width: 25px;
        height: 25px;
        color: var(--gray-color);
        
    }
}


@media(min-width: 2500px){

    .fourth-section-wrapper{
        margin: 15px 0;
    }

    .fourth-section-main-container{
        height: auto;
    }
    
}


