/*

1. Posicionamiento
2. Box Model
3. Tipografía
4. Visuales
5. Otros

*/


:root{
    --dark-color: #141516;
    --light-color: #ffffff;
    --gray-color: #3b3e41;
    --header-height-mobile: 50px;
    --mobile-font: 0.8em;
    --desktop-text-font: 1.2rem;
    --min-height-desktop: 680px;
    --line-height-full-size: 76px;
    --line-heght-middle-size: 36px; 
    --full-size-max-width: 2250px;
    --desktop-full-size-font: 3em;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.second-section-main-container{
    min-height: 100vh;
    background-color: var(--dark-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.second-section-img-container{
    width: 100%;
    height: 30vh;
    margin-top: 1vh;
    align-self: center;
}

.second-section-img-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}

.second-section-text-button-container{
    width: 90%;
    align-self: center;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.second-section-text-button-container h2{
    font-size: 31px;
    font-weight: 700;
    min-width: 220px;
    max-width: 220px;
    text-transform: uppercase;
    color: var(--light-color);
    margin-bottom: 5px;
}

.second-section-text-button-container button{
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: none;
    font-size: 24px;
    font-weight: 500;
    width: 210px;
    height: 38px;
    border-radius: 5px;
    background-color: var(--gray-color);
    color: var(--light-color);
    letter-spacing: .1em;
    padding: 0 10px;
}

.second-section-text-button-container button:hover{
    cursor: pointer;
}


.second-section-text-button-container span{
    height: 100%;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.second-section-text-button-container svg{
    margin-top: 2px;
    width: 100%;
}

.text-second-section-container{
    width: 93% !important;
    height: 25vh;
    align-self: center;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify !important;
    text-align-last: center !important;
}

.text-second-section-container p{
    font-size: var(--mobile-font);
    color: var(--light-color);
    text-align: justify !important;
    text-align-last: center !important;
    margin-top: 5px;
}

.max-container-wrapper{
    display: flex;
    align-self: center;
    flex-direction: column;
    width: 90%;
}

@media (min-width: 1200px){
    
    .second-section-main-container{
        min-height: 550px;
        height: 65vh;
        width: 100%;
        flex-flow: column;
        align-content: center;
    }

    .max-container-wrapper{
        align-self: center;
        flex-direction: row;
        width: 80%;
        justify-content: space-evenly;
        max-height: 465px;
        height: 60%;
        max-width: 1600px;
    }

    .second-section-img-container{
        width: 75%;
        height: 100%;
        max-height: 465px;
        max-width: 1350px;
        min-height: 280px;
    }
    .second-section-text-button-container{
        width: 20%;
    }
    .text-second-section-container{
        width: 80% !important;
        padding-left: 15px;
        padding-top: 25px;
        min-height: 118px;
        max-width: 1400px;
    }

    .text-second-section-container p{
        font-size: var(--desktop-text-font);
        font-weight: 500;
        text-align: justify;
        text-align-last: center;
        line-height: var(--line-heght-middle-size);
        align-self: center;
    }

    .second-section-text-button-container{
        align-items: flex-end;
    }

    .second-section-text-button-container h2{
        text-align: center;
    }
    .second-section-text-button-container button{
        text-align: center;
        height: 39px;
    }

}

@media (min-width: 350px) and (max-width: 999px){

    .text-second-section-container{
        align-items: flex-start;
        text-align: justify;
        text-align-last: center;
    }

    .text-second-section-container p{
        font-size: 1em;
        font-weight: 500;
        text-align: center;
    }
}

@media (min-width: 2800px){
    .second-section-main-container{
        height: 45vh;
    }
}


@media (min-width: 4000px){
    .second-section-main-container{
        height: 35vh;
    }
}