/*

1. Posicionamiento
2. Box Model
3. Tipografía
4. Visuales
5. Otros

*/


:root{
    --dark-color: #141516;
    --light-color: #ffffff;
    --gray-color: #3b3e41;
    --header-height-mobile: 50px;
    --mobile-font: 0.8em;
    --title-font: 1.75em;
    --desktop-text-font: 1.2rem;
    --min-height-desktop: 680px;
    --title-desktop-font: 2.3rem;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


.subsecond-section-container{
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4vh;
    padding-bottom: 2vh;
}

.subsecond-section-container h2{
    font-size: var(--title-font);
    color: var(--dark-color);
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin-top: 15px;
}

.collapsed-info-container-root{
    min-height: 40px;
    width: 90%;
    display: flex;
    margin-top: 4vh;
   
    flex-direction: column;
}

.span-item-collapsed-info{
    height: 40px;
    width: 100%;
    border: 1.5px solid var(--gray-color);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--mobile-font);
    font-weight: 700;
    border-radius: 7px;
}

.span-item-collapsed-info-uncollapsed{
    height: 40px;
    width: 100%;
    border: 1.5px solid var(--gray-color);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--mobile-font);
    font-weight: 700;
    border-radius: 7px;
    color: var(--light-color);
    background-color: var(--gray-color);
}

.span-item-collapsed-info-uncollapsed:hover{
    cursor: pointer;
}


.span-item-collapsed-info:hover{
    cursor: pointer;
    transform: translateY(0.5px);
}

.information-lawyer-container{
    width: 100%;
    min-height: 50vh;
    
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.information-lawyer-container h3{
    font-size: var(--mobile-font);
    font-weight: 700;
    color: var(--dar-color);
    margin: 7px 0;
}


.information-lawyer-container li{
    font-size: var(--mobile-font);
    font-weight: 400;
    color: var(--dark-color);
    list-style-position: inside;
    margin-bottom: 7px;
    text-align: justify;
}

.arrow-container-close-info{
    width: 100%;
    height: 35px;
    font-weight: 900;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow-close-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(3, 3, 3, 0.6);
    border-radius: 5px;
}

.arrow-close-icon:hover{
    cursor: pointer;
}

.uncollapsed{
    display: inline-block;
}

.collapsed{
    display: none;
}

.left-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.left-info ul{
    width: 100%;
}

.right-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.right-info ul{
    width: 100%;
}


@media (min-width: 1000px){
    .subsecond-section-container{
        min-height: 500px; 
    }
    .subsecond-section-container h2{
        width: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: var(--title-desktop-font);
    }

    .collapsed-info-container-root{
        display: flex;
    }

    .span-item-collapsed-info{
        align-self: center;
        width: 37%;
        border: 2px solid var(--gray-color);
        font-size: var(--desktop-text-font);
        font-weight: 600;
        max-width: 700px;
    }

    .span-item-collapsed-info-uncollapsed{
        align-self: center;
        width: 37%;
        border: 2px solid var(--gray-color);
        font-size: var(--desktop-text-font);
        font-weight: 600;
        max-width: 700px;
    }

    .information-lawyer-container{
        flex-direction: row;
        align-items: flex-start;
        padding: 15px;
        min-height: 30vh !important;
    }

    .left-info{
        width: 50%;
        min-height: 280px;
        justify-content: flex-start;
    }

    .left-info ul{
        width: 80%;
        margin-bottom: 15px;
    }
    .left-info h3{
        font-size: 1rem !important;
        text-align: justify;
        width: 80%;
    }
    .left-info li{
        font-size: 1rem !important;
    }

    .right-info{
        width: 50%;
        height: 100%;
        min-height: 280px;
        justify-content: flex-start;
    }

    .right-info ul{
        width: 80%;
        margin-bottom: 15px;
    }
    .right-info h3{
        font-size: 1rem !important;
        text-align: justify;
        width: 80%;
    }
    .right-info li{
        font-size: 1rem !important;
    }
}

@media (min-width: 350px) and (max-width: 999px){

    .collapsed-info-container-root h3{
        font-size: 1em;
    }
    .collapsed-info-container-root li{
        font-size: 1em;
    }
}

@media (min-width: 2800px){
    .subsecond-section-container{
        min-height: 40vh; 
    }
    .subsecond-section-container h2{
        width: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: 4em;
    }

    .collapsed-info-container-root{
        display: flex;
    }

    .span-item-collapsed-info{
        align-self: center;
        width: 37%;
        font-weight: 600;
        height: 90px;
        max-width: 900px;
    }

    .span-item-collapsed-info-uncollapsed{
        align-self: center;
        width: 37%;
        height: 90px;
        font-weight: 600;
        max-width: 900px;
    }

    .information-lawyer-container{
        flex-direction: row;
        align-items: flex-start;
        padding: 15px;
        max-width: 1440px;
        align-self: center;
    }

    .information-lawyer-container{
        min-height: 10vh !important;
    }

}