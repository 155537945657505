:root{
    --dark-color: #141516;
    --light-color: #ffffff;
    --gray-color: #3b3e41;
    --header-height-mobile: 50px;
    --mobile-font: 0.8em;
    --title-font: 1.75em;
    --desktop-text-font: 1.2rem;
    --min-height-desktop: 680px;
    --title-desktop-font: 2.3rem;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.footer-root-container{
    background-color:#141516;
    display: flex;
    align-items: center;
    justify-content: center;
}

.legalidad-integral-copy{
    background-color:#141516;
}
.footer-wrapper{
    width: 90%;
    display: flex;
    flex-direction: column;
}

@media (min-width: 1000px){
    .footer-root-container{
        flex-direction: row;
        min-height: 190px;
        background-color: var(--dark-color);
        background: var(--dark-color);
        align-items: center;
        justify-content: center;
        height: auto;
        overflow: hidden;
    }

    .footer-wrapper{
        width: 90%;
        flex-direction: row;
        max-width: 1450px;
        justify-content: space-between;
    }

    .legalidad-integral-copy{
        max-height: 100%;
    }

}

