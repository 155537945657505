/*

1. Posicionamiento
2. Box Model
3. Tipografía
4. Visuales
5. Otros

*/


:root{
    --dark-color: #141516;
    --light-color: #ffffff;
    --gray-color: #3b3e41;
    --header-height-mobile: 50px;
    --mobile-font: 0.8em;
    --title-font: 1.75em;
    --desktop-text-font: 1.2rem;
    --min-height-desktop: 680px;
    --title-desktop-font: 2.3rem;
    --line-height-full-size: 76px;
    --line-heght-middle-size: 36px; 
    --full-size-max-width: 2250px;
    --desktop-full-size-font: 3em;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.third-section-main-container{
    width: 100%;
    min-width: 320px;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
}

.title-third-section-container{
    width: 95%;
    align-self: center;
    height: 6vh;
    margin-top: 4vh;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.title-third-section-container h2{
    font-size: var(--title-font);
    color: var(--dark-color);
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
}

.slider-third-section-container{
    margin-top: 35px;
    width: 95%;
    height: 30vh;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.images-slider-container{
    position: relative;
    width: 95% !important;
    height: 30vh !important;
    text-align: center;
    margin: 0 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    background:linear-gradient(180deg, rgba(20,21,22,0.55) 0%, rgba(20,21,22,0.55) 100%);
}
.images-slider-container h2{
    font-size: 1.2em;
    text-transform: uppercase;
    color: var(--light-color);
    width: 100%;
    font-weight: 700;
    margin-bottom: 5px;
    z-index: 1;
}

.images-slider-container p{
    font-size: 0.5em;
    width: 95%;
    text-align: justify;
    text-align-last: center;
    color: var(--light-color);
    font-weight: 700;
    z-index: 1;
    display: flex;
    justify-content: center;
}

.overlay-container{
    position: absolute;
    width: 100%;
    height: 30vh;
    background-color: rgba(20,21,22,0.55);
}

.slider-third-section-container .arrow-icon{
    max-width: 5%;
}

.third-section-container-text{
    width: 95% !important;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-top: 20px;
    padding: 0 10px;
}

.third-section-container-text p{
    text-align: justify;
    text-align-last: center;
    font-size: var(--mobile-font);
    font-weight: 500;
    color: var(--dark-color);
}

.arrow-icon:hover{
    cursor: pointer;
}



@media (min-width: 1000px){
    .third-section-main-container{
        min-height: 400px;   
        height: auto;   
    }
    .title-third-section-container h2{
        font-size: var(--title-desktop-font);
        text-align: center;
    }

    .slider-third-section-container{
        width: 80%;
       height: 420px !important;
       max-width: 1400px;
    }
    .images-slider-container{
        height: 100% !important;
    }
    .images-slider-container h2{
        font-size: var(--title-desktop-font);
        text-align: center;
    }

    .images-slider-container p{
        font-size: var(--desktop-text-font);
        font-weight: 500;
    }

    .arrow-icon{
        height: 15%;
        width: 3%;
        font-weight: 400;
    }
    .overlay-container{
        height: 100%;
    }

    .third-section-container-text{
        padding: 0 15px;
        align-items: flex-start;
        width: 85% !important;
        height: auto;
        max-width: 1400px;
    }

    .third-section-container-text p{
        font-size: var(--desktop-text-font);
        font-weight: 500;
        margin-top: 15px;
        width: 90%;
        line-height: var(--line-heght-middle-size);
    }
}

@media (min-width: 350px) and (max-width: 999px){
    
    .images-slider-container p{
        font-size: 1em;
        font-weight: 500;
    }

   
    .third-section-container-text p{
        font-size: 1em;
        font-weight: 500;
        width: 90%;
    }
}


@media (min-width: 350px) and (max-width: 999px){
    
    .images-slider-container p{
        font-size: 1em;
        font-weight: 500;
    }

   
    .third-section-container-text p{
        font-size: 1em;
        font-weight: 500;
        width: 90%;
    }
}


