/*

1. Posicionamiento
2. Box Model
3. Tipografía
4. Visuales
5. Otros

*/


:root{
    --dark-color: #141516;
    --light-color: #ffffff;
    --gray-color: #3b3e41;
    --header-height-mobile: 80px;
    --mobile-font: 0.9em;
    --desktop-text-font: 1.2rem;
    --min-height-desktop: 30px;
    --line-height-full-size: 76px;
    --line-heght-middle-size: 36px; 
    --full-size-max-width: 2250px;
    --desktop-full-size-font: 3em;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.first-section-main-container{
    min-height: 100vh;
    background: linear-gradient(180deg, rgba(20,21,22,0.55) 0%, rgba(20,21,22,1) 100%), url('../../../../assets/first-section.jpg');   
    background-size:cover;
    background-position: center;
    object-fit: cover;
    display: flex;
    flex-direction: column;
}

.header-first-section{
    width: 100%;
    min-height: var(--header-height-mobile);
    min-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.header-body{
    min-height: 30vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 15px;
    background-color: #141516;
}
.header-body ul{
    width: 100%;
    height: 100%;
    margin-bottom: 15px;
}

.header-body li{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    
}

.header-body li>a{
    color: var(--light-color);
    font-size: 1.1em;
    font-weight: 700;
    text-decoration: none;
}

.clicked-menu{
    background-color: var(--dark-color);
}

.menu-icon-first-section{
    width: 35px;
    height: 100%;
    max-height: 50px;
    margin: 7px;
    transition: 0.7s ease;
    color: var(--light-color);
}

.logo-first-section-container{
    height: 50vh;
    /* border: 1px solid yellow; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    width: 90%;
    align-self: center;
}

.logo-first-section-container img{
    width: 100%;
    height: 17%;
    justify-content: center;
    align-items: center;
    object-fit: contain;
}

.text-container-first-section{
    width: 95% !important;
    height: calc(40vh - var(--header-height-mobile));
    align-self: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 15px;
    text-align: center;
}

.text-first-section{
    text-align: justify;
    text-align-last: center;
    color: var(--light-color);
    padding: 5px 10px;
    font-size: var(--mobile-font);
    font-weight: 400;
    font-family: "Montserrat";
}

/*  Desktop menu */

.desktop-menu{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 70%;
    align-self: center;
    min-height: 70px;
}

.desktop-menu ul{
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    width: 100%;
}

.desktop-menu li{
    list-style: none;
    text-align: center;
}

.desktop-menu a{
    color: var(--light-color);
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
}


@media (min-width: 1000px){
    .mobile-menu{
        display: none;
    }
    .menu-icon-first-section{
        display: none;
    }

    .header-first-section{
        background: none;
    }

    .header-body{
        display: none !important;
    }

    .header-first-section{
       
        align-self: center;
        position: absolute;
        top: 5px;
        max-width: 1450px;
    }

    
    
    .first-section-main-container{
        min-height: 620px;
        height: 100vh;
        justify-content: center;
        position: relative;
    }

    .logo-first-section-container{
        height: 30%;
        margin-top: 55px;
    }
   
    .logo-first-section-container img{
        width: 100%;
        height: 40%;
        margin-top: 145px !important;
        justify-content: center;
        align-items: flex-end;
        object-fit: contain;
        margin-bottom: 15px;
        max-height: 100px;
    }

    .text-container-first-section{
        width: 80% !important;
        min-height: 250px;
        max-width: 1300px;
    }
    .text-first-section{
        text-align: justify;
        text-align-last: center;
        color: var(--light-color);
        padding: 5px 15px;
        font-size: var(--desktop-text-font);
        font-weight: 500;
        font-family: "Montserrat";
        line-height: 30px;
    }

}

@media (max-width: 1000px){
    .desktop-menu{
        display: none;
    }
}

@media (min-width: 350px) and (max-width: 999px){
    .logo-first-section-container{
        height: 280px;
    }

    .logo-first-section-container img{
        width: 100%;
        height: 30%;
        margin-top: 145px !important;
        justify-content: center;
        align-items: flex-end;
        object-fit: contain;
        
    }

    .text-container-first-section{
        width: 95% !important;
        min-height: 250px;
    }

    .text-first-section{
        text-align: justify;
        text-align-last: center;
        color: var(--light-color);
        padding: 5px 15px;
        font-size: 1em;
        font-weight: 500;
        font-family: "Montserrat";
    }
}

@media (min-width: 3400px){
    .first-section-main-container{
        height: 70vh;
    }

    .header-first-section{
        top: 5%;
    }

    .logo-first-section-container{
        position: absolute;
        top: 25%;
    }

    .text-container-first-section{
        position: absolute;
        bottom: 15%;
    }
}